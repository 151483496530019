import React, { useState, useEffect } from 'react';
import OurTeam from './modules/ourTeam/ourTeam';
import Partners from './modules/partners/partners';
import Collabortors from './modules/collabortors/collaborators';
import WhoWeAreSection from './modules/whoWeAreSection/whoWeAre';
import CallToActionParticipate from '../reusableComponents/callToActionParticipate/callToActionParticipate';
import Loading from '../../helpers/loading';

const About = () => {
	const [isBusy, setBusy] = useState(true);

	useEffect(() => {
		(async () => {
			setBusy(false);
		})();
	}, [isBusy]);

	if (isBusy) return <Loading />;
	else
		return (
			<div className='about-conatiner'>
				<WhoWeAreSection />
				<OurTeam />
				<Collabortors />
				<Partners />
				<CallToActionParticipate />
			</div>
		);
};

export default About;
