import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Logo from '../../../assets/images/header/logo.png';
import config from '../../../config';
import { useLang } from '../../../helpers/language';

const Header = () => {
	let location = useLocation();

	let { lang, setlang } = useLang();

	const changeLanguage = (e) => {
		if (e.target.lang === 'ru') {
			setlang('ru');
		} else if (e.target.lang === 'hy') {
			setlang('hy');
		} else if (e.target.lang === 'en') {
			setlang('en');
		}
	};

	const activeRoute = (routeName) => {
		let route = routeName.slice(1);
		let pathName = location.pathname.slice(1);
		return pathName === route ? true : false;
	};

	const navigation = [
		{ name: config.lang.menu.home[lang], route: config.routes.home },
		{ name: config.lang.menu.aboutUs[lang], route: config.routes.aboutUs.own },
		{ name: config.lang.menu.program[lang], route: config.routes.program },
		{ name: config.lang.menu.contacts[lang], route: config.routes.contacts },
	];

	return (
		<header className='header-container'>
			<div className='header-images'>
				<div className='header-images-logo'>
					<img src={Logo} alt='logo' />
				</div>
				<div className='header-images-breil'>
					{/* <img src={BreilTop} alt='breil' /> */}
				</div>
			</div>

			{/* <div className='header-text'>Festival</div> */}

			<ul className='languages'>
				<ul>
					<li>
						<span
							className={lang === 'en' ? 'selected lang-button' : 'lang-button'}
							onClick={changeLanguage}
							lang='en'
						>
							Eng
						</span>
					</li>
					<li>
						<span
							className={lang === 'hy' ? 'selected lang-button' : 'lang-button'}
							onClick={changeLanguage}
							lang='hy'
						>
							Հայ
						</span>
					</li>
				</ul>
			</ul>
			<div className='header-menu'>
				{navigation.map((obj, index) => (
					<li
						key={index}
						className={
							activeRoute(obj.route) ? 'menu-item selected' : 'menu-item'
						}
					>
						<Link to={`${obj.route}`}>
							<span data-hover={obj.name}>{obj.name}</span>
						</Link>
					</li>
				))}
			</div>
		</header>
	);
};

export default Header;
