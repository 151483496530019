import React, { useState, useEffect } from 'react';
import config from '../../config';
import { useLang } from '../../helpers/language';
import * as API from '../../helpers/api';
import { useLocation } from 'react-router-dom';
import parse from 'html-react-parser';

export default function ProgramPage() {
	let [programPage, setprogramPage] = useState([]);
	let { lang } = useLang();
	let [isBusy, setBusy] = useState(true);
	const id = useLocation().pathname.split('/')[
		useLocation().pathname.split('/').length - 1
	];
	useEffect(() => {
		(async () => {
			const rawData = await API.get(config.routes.program, { _id: id });

			let item = rawData.data[0];

			let image = await API.getImagesByObjName(rawData.data[0]);
			item.imageUrl = image[0]
				? `${config.routes.API_URL}/${image[0].path}`
				: null;

			await setprogramPage(rawData.data[0]);
			setBusy(false);
		})();
	}, [isBusy, id]);

	if (isBusy) {
		return (
			<div className='loading'>
				{/* <img src={require('../../../images/loading.gif')} alt='' /> */}
				<h2>Loading...</h2>
			</div>
		);
	} else
		return (
			<div className='program-page-container'>
				<div className='program-page-image'>
					<img
						src={programPage ? programPage.imageUrl : null}
						alt=''
					/>
				</div>
				<div className='program-page-text-wrapper'>
					<h1 className='program-page-title'>
						{lang === 'en'
							? programPage.nameEn
							: programPage.nameHy}
					</h1>
					<div className='program-page-text'>
						{parse(
							`${
								programPage
									? lang === 'en'
										? programPage.descEn
										: programPage.descHy
									: null
							}`
						)}
					</div>
				</div>
			</div>
		);
}
