import React from 'react';
import LiveHome from './modules/live/live';
import ProgramHome from './modules/program/program';
import CallToActionParticipate from '../reusableComponents/callToActionParticipate/callToActionParticipate';

export default function Home() {
	return (
		<div className='home-page'>
			<LiveHome />
			<ProgramHome />
			<CallToActionParticipate />
		</div>
	);
}
