import React, { useState, useEffect } from 'react';
import ReusableTitle from '../../../reusableComponents/resuableTitle/reusableTitle';
import ReusableTeamSlider from '../../../reusableComponents/reusableTeamSlider/reusableTeamSlider';
import config from '../../../../config';
import { useLang } from '../../../../helpers/language';
import * as API from '../../../../helpers/api';

const OurTeam = () => {
	let [team, setteam] = useState([]);
	let { lang } = useLang();

	useEffect(() => {
		(async () => {
			const teamRawData = await API.get(config.routes.aboutUs.team);
			let teamArr = [];
			for (let i = 0; i < teamRawData.data.length; i++) {
				const item = teamRawData.data[i];
				let image = await API.getImagesByObjName(item);
				item.imageUrl = image[0]
					? `${config.routes.API_URL}/${image[0].path}`
					: null;
				const position = await API.get(config.routes.position, {
					_id: item.position_id,
				});
				item.position = position.data[0];
				teamArr.push(item);
			}

			await setteam(teamArr);
		})();
	}, []);

	return (
		<div className='ourTeam-container'>
			<ReusableTitle text={config.lang.aboutUs.team[lang]} />
			<ReusableTeamSlider data={team} lang={lang} route={true}/>
		</div>
	);
};

export default OurTeam;
