import React from 'react';
import facebookImg from '../../../assets/images/footer/facebook-circular-logo.png';
import instagramImg from '../../../assets/images/footer/instagram.png';
import telegramImg from '../../../assets/images/footer/telegram.png';
import twiterImg from '../../../assets/images/footer/twitter.png';
import yerevanPerfFestival from '../../../assets/images/footer/yerevanPerfFestival.png';
import config from '../../../config';
import { useLang } from '../../../helpers/language';

const Footer = () => {
	const { lang } = useLang();

	return (
		<>
			<footer className='footer'>
				<div className='footer-logo-wrapper'>
					<div>
						<img src={yerevanPerfFestival} alt="fest"/>
					</div>
					<div className='copyRight'>
						<span>© {config.contacts.copyright[lang]}</span>
					</div>
				</div>
				<div className='footer-contacts'>
					<div className='address'>
						<span>{config.contacts.address.arm[lang]}</span>
						<span>
							<a href={`mailto: ${config.contacts.email.email1}`}>
								{`${config.contacts.email.arm[lang]} ${config.contacts.email.email1}`}
							</a>
						</span>
						<span>
							<a href={`tel: ${config.contacts.tel.tel1}`}>
								{`${config.contacts.tel.arm[lang]} ${config.contacts.tel.tel1}`}
							</a>
						</span>
					</div>
					<br />
					{lang === 'en' ? (
						<>
							<div className='address'>
								<span>{config.contacts.address.fra[lang]}</span>
								<span>
									<a href={`mailto: ${config.contacts.email.email2}`}>
										{`${config.contacts.email.fra[lang]} ${config.contacts.email.email2}`}
									</a>
								</span>
								<span>
									<a href={`tel: ${config.contacts.tel.tel2}`}>
										{`${config.contacts.tel.fra[lang]} ${config.contacts.tel.tel2}`}
									</a>
								</span>
							</div>
						</>
					) : null}
					<br />
					<div className='share-items'>
						<div className='share-item'>
							<a href={config.contacts.facebook} rel="noopener noreferrer" target='_blank'>
								<img src={facebookImg} alt='share-items' />
							</a>
						</div>
						<div className='share-item'>
							<a href={config.contacts.instagram} rel="noopener noreferrer" target='_blank'>
								<img src={instagramImg} alt='share-items' />
							</a>
						</div>
						<div className='share-item'>
							<a href={config.contacts.telegram} rel="noopener noreferrer" target='_blank'>
								<img src={telegramImg} alt='share-items' />
							</a>
						</div>
						<div className='share-item'>
							<a href={config.contacts.twitter} rel="noopener noreferrer" target='_blank'>
								<img src={twiterImg} alt='share-items' />
							</a>
						</div>
					</div>
				</div>
			</footer>

			<div className='deepmindsystems'>
				<span>{config.lang.footer.madeBy[lang]} </span>
				{/* <img src={deepMindLogo} /> */}
				<a href='https://deepmindsystems.com'>Deepmindsystems.com</a>
			</div>
		</>
	);
};

export default Footer;
