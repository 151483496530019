import React, { useState, useEffect } from 'react';
import ReusableTitle from '../../../reusableComponents/resuableTitle/reusableTitle';
import ReusableTeamSlider from '../../../reusableComponents/reusableTeamSlider/reusableTeamSlider';
import config from '../../../../config';
import { useLang } from '../../../../helpers/language';
import * as API from '../../../../helpers/api';
import Loading from '../../../../helpers/loading';

const Partners = () => {
	let [partners, setpartners] = useState([]);
	let { lang } = useLang();
	const [isBusy, setBusy] = useState(true);

	useEffect(() => {
		(async () => {
			const partnerRawData = await API.get(config.routes.aboutUs.partners);
			let partnersArr = [];
			for (let i = 0; i < partnerRawData.data.length; i++) {
				const item = partnerRawData.data[i];
				let image = await API.getImagesByObjName(item);
				item.imageUrl = image[0]
					? `${config.routes.API_URL}/${image[0].path}`
					: null;
				partnersArr.push(item);
			}

			await setpartners(partnersArr);
			setBusy(false);
		})();
	}, [isBusy]);

	if (isBusy) return <Loading />;
	else
		return (
			<div className='partners-container'>
				<ReusableTitle text={config.lang.aboutUs.partners[lang]} />
				<ReusableTeamSlider data={partners} lang={lang} />
			</div>
		);
};

export default Partners;
