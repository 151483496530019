import React, { useState, useEffect } from 'react';
import config from '../../config';
import { useLang } from '../../helpers/language';
import * as API from '../../helpers/api';
import Pagination from '../../helpers/paginate';
import { Link } from 'react-router-dom';
import Loading from '../../helpers/loading';

const PaginationContent = ({ paginationContent, loading, lang }) => {
	if (loading) {
		return (
			<div className='loading'>
				<Loading/>
			</div>
		);
	}
	return paginationContent.map((pgnContent, index) => (
		<div className='program-wrapp' key={index}>
			<div className='program'>
				<span className='program-date'>
					{` ${
						config.lang.months[lang][new Date(`${pgnContent.date}`).getMonth()]
					} ${new Date(`${pgnContent.date}`).getDate()}`}
				</span>
				<img src={pgnContent.imageUrl} alt="programm"/>
				<Link to={`/program/${pgnContent._id}`}>
					<div className='title-wrapp'>
						<span>{lang === 'en' ? pgnContent.nameEn : pgnContent.nameHy}</span>
					</div>
				</Link>
			</div>
		</div>
	));
};

const Program = () => {
	let [program, setprogram] = useState([]);
	let { lang } = useLang();
	let [isBusy, setBusy] = useState(true);
	let [screenWidth] = useState(window.innerWidth.toString());

	//pagination
	const [currentPage, setCurrentPage] = useState(1);
	const [dataPerPage] = useState(screenWidth > 1270 ? 6 : 4);
	// Change page
	const paginate = (pageNumber) => setCurrentPage(pageNumber);
	// Get current posts
	const indexOfLastPost = currentPage * dataPerPage;
	const indexOfFirstPost = indexOfLastPost - dataPerPage;
	const currentData = program.slice(indexOfFirstPost, indexOfLastPost);

	useEffect(() => {
		(async () => {
			const rawData = await API.get(config.routes.program);

			let initialArr = [];

			for (let i = 0; i < rawData.data.length; i++) {
				const item = rawData.data[i];
				let image = await API.getImagesByObjName(item);
				item.imageUrl = image[0]
					? `${config.routes.API_URL}/${image[0].path}`
					: null;
				initialArr.push(item);
			}

			await setprogram(initialArr);
			setBusy(false);
		})();
	}, [isBusy]);

	if (isBusy) return <Loading />;
	else
		return (
			<div className='program-container'>
				<div className='program-image-wrapper'>
					{program !== undefined ? (
						<>
							<PaginationContent
								paginationContent={currentData}
								loading={isBusy}
								lang={lang}
							/>
							<Pagination
								dataPerPage={dataPerPage}
								totalCount={program.length}
								paginate={paginate}
								currentPage={currentPage}
							/>
						</>
					) : (
						<h2 className='text-center w-100 align-self-center'>
							Here are no content to show
						</h2>
					)}
				</div>
			</div>
		);
};

export default Program;
