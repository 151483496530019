import React, { useState, useEffect } from 'react';
import config from '../../config';
import { useLang } from '../../helpers/language';
import CallToActionParticipate from '../reusableComponents/callToActionParticipate/callToActionParticipate';
import ArmeniaFlag from '../../assets/images/armenia.png';
import FrenchFlag from '../../assets/images/france.png';
import Loading from '../../helpers/loading';

const Contacts = () => {
	let { lang } = useLang();
	const [isBusy, setBusy] = useState(true);
	useEffect(() => {
		(async () => {
			setBusy(false);
		})();
	}, [isBusy]);
	if (isBusy) return <Loading />;
	else
		return (
			<div className='contacts-page'>
				<div className='contacts-page-text'>
					<div>
						<img
							className='contacts-page-flag'
							src={ArmeniaFlag}
							alt='armenia flag'
						/>
						<br />
						ARMENIA
						<br />
						{config.contacts.address.arm[lang]}
						<br />
						<a href={`mailto: ${config.contacts.email.email1}`}>
							{`${config.contacts.email.arm[lang]} ${config.contacts.email.email1}`}
						</a>
						<br />
						<a href={`tel: ${config.contacts.tel.tel1}`}>
							{`${config.contacts.tel.arm[lang]} ${config.contacts.tel.tel1}`}
						</a>
						<br />
						<br />
						<br />
						{lang === 'en' ? (
							<>
								<img
									className='contacts-page-flag'
									src={FrenchFlag}
									alt='france flag'
								/>
								<br />
								FRANCE
								<br />
								{config.contacts.address.fra[lang]}
								<br />
								<a href={`mailto: ${config.contacts.email.email2}`}>
									{`${config.contacts.email.fra[lang]} ${config.contacts.email.email2}`}
								</a>
								<br />
								<a href={`tel: ${config.contacts.tel.tel2}`}>
									{`${config.contacts.tel.fra[lang]} ${config.contacts.tel.tel2}`}
								</a>
							</>
						) : null}
					</div>
				</div>
				<CallToActionParticipate />
			</div>
		);
};

export default Contacts;
