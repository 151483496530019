import React, { useState, useEffect } from 'react';
import config from '../../config';
import { useLang } from '../../helpers/language';
import * as API from '../../helpers/api';
import { useLocation } from 'react-router-dom';
import parse from 'html-react-parser';
import OurTeam from '../about/modules/ourTeam/ourTeam';
import Loading from '../../helpers/loading'

export default function TeamPage() {
	let [teamPage, setteamPage] = useState([]);
	let { lang } = useLang();
	let [isBusy, setBusy] = useState(true);
	const id = useLocation().pathname.split('/')[
		useLocation().pathname.split('/').length - 1
	];
	useEffect(() => {
		(async () => {
			const rawData = await API.get(config.routes.aboutUs.team, {
				_id: id,
			});

			let item = rawData.data[0];

			let image = await API.getImagesByObjName(rawData.data[0]);
			item.imageUrl = image[0]
				? `${config.routes.API_URL}/${image[0].path}`
				: null;

			await setteamPage(rawData.data[0]);
			setBusy(false);
		})();
	}, [isBusy, id]);

	if (isBusy) {
		return (
			<div className='loading'>
				<Loading/>
			</div>
		);
	} else
		return (
			<div className='team-page-container'>
				<div className='team-page-image'>
					<img src={teamPage ? teamPage.imageUrl : null} alt='' />
				</div>
				<div className='team-page-text-wrapper'>
					<h1 className='team-page-title'>
						{lang === 'en' ? teamPage.nameEn : teamPage.nameHy}
					</h1>
					<div className='team-page-text'>
						{parse(
							`${
								teamPage
									? lang === 'en'
										? teamPage.descEn
										: teamPage.descHy
									: null
							}`
						)}
					</div>
				</div>

				<OurTeam />
			</div>
		);
}
