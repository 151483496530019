import axios from 'axios';
import { token } from './cookies';
import config from '../config';

const API = axios.create({
	baseURL: `${config.routes.API_URL}/v1/`,
});

export function get(route, query) {
	return new Promise((resolve, reject) => {
		if (query) {
			API.get(`${route}?q=${JSON.stringify(query)}`, {
				headers: {
					authorization: token,
				},
			})
				.then((res) => resolve(res))
				.catch((error) => reject(error));
		} else {
			API.get(`${route}`, {
				headers: {
					authorization: token,
				},
			})
				.then((res) => resolve(res))
				.catch((error) => reject(error));
		}
	});
}
export function getById(route, id) {
	return new Promise((resolve, reject) => {
		API.get(`${route}/${id}`, {
			headers: {
				authorization: token,
			},
		})
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
}
export function post(route, data) {
	return new Promise((resolve, reject) => {
		API.post(route, data, {
			headers: {
				authorization: token,
			},
		})
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
}
export function update(route, id, data) {
	return new Promise((resolve, reject) => {
		API.put(`${route}/${id}`, data, {
			headers: {
				authorization: token,
			},
		})
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
}
export function remove(route, id) {
	return new Promise((resolve, reject) => {
		API.delete(`${route}/${id}`, {
			headers: {
				authorization: token,
			},
		})
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
}
export function getImagesByObjName(obj) {
	return new Promise((resolve, reject) => {
		API.get(
			`${config.routes.upload}?q=${JSON.stringify({
				type: { $regex: '.*image.*' },
				obj_id: obj._id,
			})}`
		)
			.then((res) => resolve(res.data))
			.catch((err) => reject(err));
	});
}
export const upload = (id, files, lang) => {
	let promiseArray = [];
	if (id) {
		files.map((file, index) => {
			return promiseArray.push(
				new Promise((resolve, reject) => {
					const formData = new FormData();
					formData.append('obj_id', id);
					formData.append('file', file);
					formData.append('lang', lang);
					API.post(config.routes.upload, formData, {
						headers: {
							authorization: token,
						},
					})
						.then((res) => resolve(res))
						.catch((error) => reject(error));
				})
			);
		});
		let result = Promise.all(promiseArray).then(true);
		return result;
	} else {
		console.error(
			'You must provide id for upload element to be assigned to'
		);
	}
};

export default API;
