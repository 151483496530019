import React, { useState } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { Link } from 'react-router-dom';

const ReusableTeamSlider = ({ data, lang, route }) => {
	let [screenWidth] = useState(window.innerWidth.toString());

	return (
		<div className='ourTeam-img-wrapper'>
			<Splide
				options={{
					type: 'slide',
					perPage: screenWidth < 550 ? 1 : screenWidth < 890 ? 3 : 5,
					perMove: 1,
					pagination: false,
					lazyLoad: 'sequential',
				}}
			>
				{data
					? data.map((item, index) => (
							<SplideSlide key={index}>
								{route ? (
									<Link to={`/team-member/${item._id}`}>
										<img
											src={item.imageUrl}
											alt='team member 1'
											className='img-our-team'
										/>
										<div className='name'>
											<span>{lang === 'en' ? item.nameEn : item.nameHy}</span>
										</div>
										<div className='position'>
											<span>
												{item.position
													? lang === 'en'
														? item.position.nameEn
														: item.position.nameHy
													: null}
											</span>
										</div>
									</Link>
								) : (
									<>
										<img
											src={item.imageUrl}
											alt='team member 1'
											className='img-our-team'
										/>
										<div className='name'>
											<span>{lang === 'en' ? item.nameEn : item.nameHy}</span>
										</div>
										<div className='position'>
											<span>
												{item.position
													? lang === 'en'
														? item.position.nameEn
														: item.position.nameHy
													: null}
											</span>
										</div>
									</>
								)}
							</SplideSlide>
					  ))
					: null}
			</Splide>
		</div>
	);
};

export default ReusableTeamSlider;
