const config = {
	routes: {
		home: '/home',
		aboutUs: {
			own: '/about',
			self: 'aboutUs/self',
			collaborators: 'aboutUs/collaborators',
			partners: 'aboutUs/partners',
			team: 'aboutUs/team',
		},
		contacts: '/contacts',
		program: '/program',
		upload: 'uploads',
		position: 'position',
		live: 'live',

		teamMember: 'team-member',

		API_URL: process.env.REACT_APP_API_URL,
	},
	contacts: {
		tel: {
			tel1: '+37498190262',
			tel2: '+33665664468',
			arm: {
				hy: 'Հեռ :',
				en: 'Tel :',
			},
			fra: {
				en: 'Tel in France :',
			},
		},
		whatsapp: '+374 95 511 005',
		viber: '+374 95 511 005',
		facebook: 'https://www.facebook.com/perffestival',
		instagram: 'https://www.instagram.com/perffestival',
		telegram: 'https://t.me/festivalperf',
		twitter: 'https://twitter.com/perfyerevan',
		email: {
			email1: 'festival.yerevan.perf@gmail.com',
			email2: 'estnovaproduction@gmail.com',
			arm: {
				hy: 'էլ․ հասցե : ',
				en: 'Email : ',
			},
			fra: {
				hy: 'էլ․ հասցե : ',
				en: 'Email for France : ',
			},
		},
		address: {
			arm: {
				en: 'Address : Hunan Avetisyan 58, 0092, Yerevan,',
				hy: 'Հասցե ։ ք․ Երևան, Հունան Ավետիսյան 58, 0092',
			},
			fra: {
				en: 'Address in France : 8 Rue du Général Renault, 75011 Paris, Françe',
			},
		},
		copyright: {
			en: 'All rights belongs to Perf festival 2020',
			hy: 'Բոլոր իրավունքնորը պատկանում են Պեռֆ Ֆեստիվալին 2020',
		},
	},
	lang: {
		topbar: {
			workingDays: {
				hy: 'Երկուշաբթի - Ուրբաթ',
				ru: 'Понедельник - Пятница',
				en: 'Monday - Friday',
			},
		},
		menu: {
			home: {
				hy: 'ՀԻՄՆԱԿԱՆ',
				en: 'HOME',
			},
			aboutUs: {
				hy: 'ՄԵՐ ՄԱՍԻՆ',
				en: 'ABOUT US',
			},
			program: {
				hy: 'ԾՐԱԳԻՐ',
				en: 'PROGRAM',
			},
			contacts: {
				hy: 'ԿՈՆՏԱԿՏՆԵՐ',
				en: 'CONTACTS',
			},
		},
		homePage: {
			live: {
				hy: 'Ուղիղ',
				en: 'Live',
			},

			news: {
				title: {
					hy: 'Նորություններ',
					ru: 'Новости',
					en: 'News',
				},
				subtitle: {
					hy: 'Հիմնական Ծառայություններ',
					ru: 'Основные Сервисы',
					en: 'Main Services',
				},
			},
		},
		aboutUs: {
			artcollaborationButton: {
				hy: 'Դառնալ մասնակից',
				en: 'Participate',
			},
			bussinesscollaborationButton: {
				hy: 'Դառնալ հովանավոր',
				en: 'Be Our Partner',
			},
			collaborators: {
				hy: 'Համագործակիցներ',
				en: 'Art Collaborators',
			},
			partners: {
				hy: 'Գործընկերներ',
				en: 'Partners',
			},
			team: {
				hy: 'Մեր թիմը',
				en: 'Team',
			},
		},
		months: {
			hy: [
				'ՀՈՒՆ',
				'ՓԵՏ',
				'ՄԱՐՏ',
				'ԱՊՐ',
				'ՄԱՅ',
				'ՀՈՒՆ',
				'ՀՈՒԼ',
				'ՕԳՍ',
				'ՍԵՊ',
				'ՀՈԿ',
				'ՆՈՅ',
				'ԴԵԿ',
			],
			ru: [
				'ЯНВ',
				'ФЕВ',
				'МАР',
				'АПР',
				'МАЙ',
				'ИЮНЬ',
				'ИЮЛЬ',
				'АВГ',
				'СЕН',
				'ОКТ',
				'НОЯ',
				'ДЕК',
			],
			en: [
				'JAN',
				'FEB',
				'MAR',
				'APR',
				'MAY',
				'JUN',
				'JUL',
				'AUG',
				'SEP',
				'OCT',
				'NOV',
				'DEC',
			],
		},
		footer: {
			madeBy: {
				hy: 'Նախագծում և դիզայն՝ ',
				en: 'Designed and developed by ',
			},
		},
	},
};

export default config;
