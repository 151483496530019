import React from 'react';
const Loading = () => {
	return (
		<>
			<div className='peeek-loading'>
				<ul>
					<li></li>
					<li></li>
					<li></li>
					<li></li>
					<li></li>
					<li></li>
					<li></li>
					<li></li>
					<li></li>
					<li></li>
				</ul>
			</div>
		</>
	);
};
export default Loading;
