import React from 'react';
import tvImg from '../../../assets/images/TV.png';
import { Link } from 'react-router-dom';

const ReusableTitle = ({ text, img = false, href }) => {
	return (
		<div className='title-wrapper'>
			<div className='title-line' />
			{img ? (
				<div className='title-img-text'>
					<span>{text}</span>
					<img src={tvImg} alt='reusable title' className='image-tv' />
				</div>
			) : (
				<div className='title-text'>
					{href ? (
						<span>
							<Link to={href}>{text}</Link>
						</span>
					) : (
						<span> {text} </span>
					)}
				</div>
			)}
			<div className='title-line' />
		</div>
	);
};

export default ReusableTitle;
