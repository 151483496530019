import React, { useState, useEffect } from 'react';
import config from '../../../../config';
import { useLang } from '../../../../helpers/language';
import * as API from '../../../../helpers/api';
import ReactPlayer from 'react-player';
import Loading from '../../../../helpers/loading';

const LiveHome = () => {
	let [live, setlive] = useState([]);
	let { lang } = useLang();
	const [isBusy, setBusy] = useState(true);

	useEffect(() => {
		(async () => {
			const apiRawData = await API.get(config.routes.live);
			await setlive(apiRawData.data[0]);
			setBusy(false);
		})();
	}, [isBusy]);

	if (isBusy) return <Loading />;
	else
		return (
			<div className='live-container'>
				<div className='live-title-wrapper'>
					<div className='live-title'>
						<span>
							{lang === 'en'
								? live.nameEn
								: live.nameHy
								? live.nameHy
								: 'Live name'}
						</span>
					</div>
				</div>

				<ReactPlayer
					url={`${live.URL}`}
					className={'youTube-video'}
					playing={true}
					loop={true}
					width={null}
					height={null}
				/>
			</div>
		);
};

export default LiveHome;
