import React, { useState, useEffect } from 'react';
import config from '../../../../config';
import { useLang } from '../../../../helpers/language';
import * as API from '../../../../helpers/api';
import parse from 'html-react-parser';
import Loading from '../../../../helpers/loading';

const WhoWeAreSection = () => {
	let [whoweare, setwhoweare] = useState([]);
	let { lang } = useLang();
	const [isBusy, setBusy] = useState(true);

	useEffect(() => {
		(async () => {
			const whoweareRawData = await API.get(config.routes.aboutUs.self);
			let whowearesArr = [];

			for (let i = 0; i < whoweareRawData.data.length; i++) {
				const item = whoweareRawData.data[i];
				let image = await API.getImagesByObjName(item);
				item.imageUrl = image[0]
					? `${config.routes.API_URL}/${image[0].path}`
					: null;
				whowearesArr.push(item);
			}

			await setwhoweare(whowearesArr);
			setBusy(false);
		})();
	}, [isBusy]);

	if (isBusy) return <Loading />;
	else
		return (
			<div className='whoWeAre-container'>
				<div className='whoWeAre-image'>
					<img src={whoweare[0] ? whoweare[0].imageUrl : null} alt='' />
				</div>
				<div className='whoWeAre-text-wrapper'>
					<div>
						{parse(
							`${
								whoweare[0]
									? lang === 'en'
										? whoweare[0].descEn
										: whoweare[0].descHy
									: null
							}`
						)}
					</div>
				</div>
			</div>
		);
};

export default WhoWeAreSection;
