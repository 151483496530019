import React, { useEffect, useState } from 'react';
import Header from './Header/header';
import Footer from './Footer/footer';
import Loading from '../../helpers/loading';

export default function MainLayout({ component: Component, ...rest }) {
	let [isBusy, setIsBusy] = useState(true);

	useEffect(() => {
		(async () => {
			await setIsBusy(false);
		})();
	}, [isBusy]);

	if (isBusy) return <Loading />;
	else
		return (
			<>
				<Header />
				<div className='content-wrapper'>
					<Component />
				</div>
				<Footer />
			</>
		);
}
