import React, { useState } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { Link } from 'react-router-dom';

import config from '../../../../config';
import { useLang } from '../../../../helpers/language';
const SliderProgram = (props) => {
	let { lang } = useLang();
	let [screenWidth] = useState(window.innerWidth.toString());

	return (
		<Splide
			options={{
				// type: 'loop',
				perPage: screenWidth < 550 ? 1 : screenWidth < 890 ? 2 : 3,
				perMove: 1,
				pagination: false,
				gap: '1rem',
			}}
			className='prog-slider-container'
		>
			{props.program.map((data, index) => (
				<SplideSlide key={index}>
					<Link to={`/program/${data._id}`}>
						<img
							src={data.imageUrl}
							alt='programm 1'
							className='prog-slider-img'
						/>
						<div className='title-wrapp'>
							<span>
								{lang === 'en'
									? data.nameEn
										? data.nameEn.slice(0, 40) + '...'
										: ''
									: lang === 'hy'
									? data.nameHy
										? data.nameHy.slice(0, 40) + '...'
										: ''
									: ''}
							</span>
						</div>
						<span className='program-date'>{` ${
							config.lang.months[lang][new Date(`${data.date}`).getMonth()]
						} ${new Date(`${data.date}`).getDate()}`}</span>
					</Link>
				</SplideSlide>
			))}
		</Splide>
	);
};

export default SliderProgram;
