import React, { useState, useEffect } from 'react';
import ReusableTitle from '../../../reusableComponents/resuableTitle/reusableTitle';
import ReusableTeamSlider from '../../../reusableComponents/reusableTeamSlider/reusableTeamSlider';
import config from '../../../../config';
import { useLang } from '../../../../helpers/language';
import * as API from '../../../../helpers/api';
import Loading from '../../../../helpers/loading';

const Collabortors = () => {
	let [collaborators, setcollaborators] = useState([]);
	let { lang } = useLang();
	const [isBusy, setBusy] = useState(true);

	useEffect(() => {
		(async () => {
			const collaboratorsRawData = await API.get(
				config.routes.aboutUs.collaborators
			);

			let collaboratorsArr = [];
			for (let i = 0; i < collaboratorsRawData.data.length; i++) {
				const item = collaboratorsRawData.data[i];
				let image = await API.getImagesByObjName(item);
				item.imageUrl = image[0]
					? `${config.routes.API_URL}/${image[0].path}`
					: null;
				collaboratorsArr.push(item);
			}
			await setcollaborators(collaboratorsArr);
			setBusy(false);
		})();
	}, [isBusy]);

	if (isBusy) return <Loading />;
	else
		return (
			<div className='collabortors-container'>
				<ReusableTitle text={config.lang.aboutUs.collaborators[lang]} />
				<ReusableTeamSlider data={collaborators} lang={lang} />
			</div>
		);
};

export default Collabortors;
