import React, { useState, useEffect } from 'react';
import SliderProgram from './sliderProgramHome';
import ReusableTitle from '../../../../components/reusableComponents/resuableTitle/reusableTitle';
import config from '../../../../config';
import { useLang } from '../../../../helpers/language';
import * as API from '../../../../helpers/api';
import Loading from '../../../../helpers/loading';

const ProgramHome = () => {
	let [program, setprogram] = useState([]);
	let { lang } = useLang();
	const [isBusy, setBusy] = useState(true);

	useEffect(() => {
		(async () => {
			const rawData = await API.get(config.routes.program);

			let initialArr = [];

			for (let i = 0; i < rawData.data.length; i++) {
				const item = rawData.data[i];
				let image = await API.getImagesByObjName(item);
				item.imageUrl = image[0]
					? `${config.routes.API_URL}/${image[0].path}`
					: null;
				initialArr.push(item);
			}

			await setprogram(initialArr);
			setBusy(false);
		})();
	}, [isBusy]);

	if (isBusy) return <Loading />;
	else {
		return (
			<div className='program-container'>
				<ReusableTitle
					text={config.lang.menu.program[lang]}
					href={config.routes.program}
				/>
				<SliderProgram program={program} />
			</div>
		);
	}
};

export default ProgramHome;
