import React, { Suspense } from 'react';
//Routing
import { Router, Route, Switch } from 'react-router-dom';
// styles
import './App.sass';

// Helpers
import history from './helpers/history';
import { useStateWithLocalStorage } from './helpers/storage';
import { Language } from './helpers/language';
import Loading from './helpers/loading';

//components
import Home from './components/home/home';
import About from './components/about/about';
import Program from './components/program/program';
import ProgramPage from './components/program/programPage';
import TeamPage from './components/team/teamPage';
import Contacts from './components/contacts/contacts';

// global components
import MainLayout from './components/mainLayout/mainLayout';

function Routing() {
	return (
		<Route path='/' component={Home}>
			<Route exact path='/' component={Home} />
			<Route exact path='/home' component={Home} />
			<Route exact path='/about' component={About} />
			<Route exact path='/program' component={Program} />
			<Route exact path='/program/:id' component={ProgramPage} />
			<Route exact path='/team-member/:id' component={TeamPage} />
			<Route exact path='/contacts' component={Contacts} />
		</Route>
	);
}

function App() {
	let [lang, setlang] = useStateWithLocalStorage('language');
	if (lang === '') setlang('en');
	return (
		<Language.Provider value={{ lang, setlang }}>
			<Router history={history}>
				<Suspense fallback={<Loading />}>
					<Switch>
						<MainLayout component={Routing} />
					</Switch>
				</Suspense>
			</Router>
		</Language.Provider>
	);
}

export default App;
