import React, { useState, useEffect } from 'react';
import config from '../../../config';
import { useLang } from '../../../helpers/language';
import Loading from '../../../helpers/loading'

const CallToActionParticipate = () => {
	let { lang } = useLang();
	const [isBusy, setBusy] = useState(true);

	useEffect(() => {
		(async () => {
			setBusy(false);
		})();
	}, [isBusy]);

	if (isBusy) return <Loading />;
	else return (
		<div className='call-to-action-participate'>
			<button>
				<a href='mailto: festival.yerevan.perf@gmail.com'>
					<span>{config.lang.aboutUs.artcollaborationButton[lang]}</span>
				</a>
			</button>
			<button>
				<a href='mailto: festival.yerevan.perf@gmail.com'>
					<span>{config.lang.aboutUs.bussinesscollaborationButton[lang]}</span>
				</a>
			</button>
		</div>
	);
};
export default CallToActionParticipate;
