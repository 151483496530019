import React from 'react';

const Pagination = ({ dataPerPage, totalCount, paginate, currentPage }) => {
	const pageNumbers = [];

	for (let i = 1; i <= Math.ceil(totalCount / dataPerPage); i++) {
		pageNumbers.push(i);
	}

	return (
		<nav className='pagination-nav'>
			<ul className='pagination-ul'>
				{pageNumbers.map((number) => (
					<li key={number} className='page-item'>
						<button
							onClick={() => paginate(number)}
							className={currentPage === number ? 'page-link selected' : 'page-link'}
						>
							{number}
						</button>
					</li>
				))}
			</ul>
		</nav>
	);
};

export default Pagination;
